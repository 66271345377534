.backdrop {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  height: 100%;
}
.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
html {
  height: 100%;
}
body {
  color: #4f5447;
  min-height: 100%;
  text-align: center;
  position: relative;
}
#root {
  min-height: 100%;
  height: 100%;
  position: static;
}
.header {
  margin-bottom: 30px;
}
.header img {
  max-width: 100%;
}
.header .title {
  letter-spacing: 4.5px;
  text-transform: uppercase;
  text-align: center;
}
.background {
  background: url("../img/hatter-no-cloud-no-brown.jpg");
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
  background-size: cover;
}
.App {
  z-index: 10;
  position: relative;
  padding-top: 10px;
}
.table {
  text-align: center;
  background: #00A665;
  padding: 10px;
  margin-bottom: 10px;
  color: white;
}
.table td {
  text-align: center;
}
.table .table__title {
  font-size: 36px;
  line-height: 36px;
  border-bottom: 2px dotted black;
}
.table .table__min-max {
  border-top-color: black;
  border-top-style: dotted;
}
.table .left {
  border-right: 2px dotted black;
}
.component-result {
  background-color: #00A665;
  border-radius: 15px;
  transition: all 0.3s ease;
  min-height: 150px;
  padding: 0 15px;
  margin-bottom: 10px;
}
.component-result .box-title {
  display: table-cell;
  height: 60px;
  width: 0;
  padding: 0;
  color: #fff;
  font-size: 40px;
}
.component-result .box-content {
  display: inline-block;
  width: 45%;
  padding-bottom: 2px;
  padding-top: 5px;
  border-top: 2px dotted #fff;
  color: white;
  text-align: center;
  font-size: 20px;
}
.component-result .box-right {
  width: 49%;
  border-right: 2px dotted #fff;
}
.button {
  background: #00A665;
  padding: 10px;
  border-radius: 10px;
  outline: 0;
  display: inline-block;
  margin-bottom: 10px;
  color: white;
  font-size: 20px;
  width: 100%;
  text-decoration: none;
  border: 0;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.contact-btn {
  background-color: #C2DD72 !important;
  color: #333 !important;
}
.button:hover {
  color: white !important;
}
.bordered-input {
  padding: 10px;
  border-radius: 15px;
  border: 0;
  font-size: 20px;
  width: 100%;
  text-align: center;
  line-height: 60px;
  margin-bottom: 0;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.custom-container {
  padding-left: 28px;
  padding-right: 28px;
}
.calculated-text {
  font-size: 24px;
}
select {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: 0;
  border-right: 15px solid transparent;
  width: 100%;
  border-radius: 15px;
  margin-bottom: 30px;
  margin-top: 10px;
  padding: 10px;
  font-size: 20px;
  text-align: center;
  line-height: 60px;
  height: 80px;
  color: #757575;
}
.select-prompt {
  color: #757575;
}
.calculator button {
  margin-top: 30px;
  margin-bottom: 0px;
}
.calculator p {
  margin: 0;
}
.link {
  font-size: 20px;
  background: transparent;
  border: 0;
  outline: 0;
  color: #4f5447 !important;
}
.organic-material-subtitle {
  text-align: left;
  width: 100%;
  display: block;
  color: white;
  margin-bottom: 10px;
}
